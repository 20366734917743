import { gql } from '@apollo/client';
import { toast } from 'react-toastify';

import { client } from '../apollo';
import ApolloService from '../service';

const getAllQuery = gql`
  query GetVideos($filters: VideoFilterInput) {
    videos(filters: $filters) {
      id
      title
      authors {
        id
        name
      }
      genre {
        name
      }
      subgenre {
        name
      }
      conditions {
        name
      }
      tags {
        id
        name
      }
      featuredFromDate
      featuredToDate
      expirationDate
      status
      isNew
    }
    videoTotals {
      pageCount
      count
    }
  }
`;

const getQuery = gql`
  query GetVideo($id: String!) {
    video(id: $id) {
      id
      title
      shortDescription
      authors {
        id
        name
      }
      genre {
        id
        name
        subgenres {
          id
          name
        }
      }
      subgenre {
        id
        name
      }
      conditions {
        id
        name
      }
      labels {
        id
        name
      }
      tags {
        id
        name
      }
      thumbnail {
        id
        url
      }
      videoUrl
      videoFile {
        id
        name
        url
      }
      featuredFromDate
      featuredToDate
      startDate
      expirationDate
      publisher
      publishedOn
      videoDuration
      status
      isNew
    }
  }
`;

const createMutation = gql`
  mutation CreateVideo($input: CreateVideoInput!) {
    createVideo(input: $input) {
      id
    }
  }
`;

const updateMutation = gql`
  mutation UpdateVideo($id: String!, $input: UpdateVideoInput!) {
    updateVideo(id: $id, input: $input) {
      id
    }
  }
`;

const deleteMutation = gql`
  mutation DeleteVideo($id: String!) {
    deleteVideo(id: $id) {
      id
    }
  }
`;

const exportMutation = gql`
  mutation ExportVideos($filters: VideoAdminFilterInput) {
    exportVideos(filters: $filters) {
      token
      link
    }
  }
`;

const createSignatureMutation = gql`
  mutation CreateSignatureMutation {
    createCloudinaryUploadSignature {
      publicId
      signature
      timestamp
      folder
    }
  }
`;

const addCloudinaryFile = gql`
  mutation CreateVideo($publicId: String!) {
    addCloudinaryFile(publicId: $publicId) {
      id
      url
      name
    }
  }
`;

export default class VideoApolloService extends ApolloService {
  constructor(entity) {
    super(entity, {
      createMutation: createMutation,
      updateMutation: updateMutation,
      deleteMutation: deleteMutation,
      exportMutation: exportMutation,
      exportKey: 'exportVideos',
      getQuery: getQuery,
      getAllQuery: getAllQuery,
      getAllQueryResponseTransform: (data) => ({
        data: data.videos,
        total: data.videoTotals,
      }),
    });
  }

  async createSignature() {
    try {
      const res = await client.mutate({ mutation: createSignatureMutation });

      return res.data.createCloudinaryUploadSignature;
    } catch (e) {
      toast.error(`Cloudinary signature creating error: ${e?.message}`);
    }
  }

  async addCloudinaryFile(publicId) {
    try {
      const res = await client.mutate({ mutation: addCloudinaryFile, variables: { publicId } });

      return res.data.addCloudinaryFile;
    } catch (e) {
      toast.error(`Cloudinary file adding error: ${e?.message}`);
    }
  }
}
