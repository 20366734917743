import { gql } from '@apollo/client';

import ApolloService from '../service';

const getAllQuery = gql`
  query GetAuthors($filters: AuthorFilterInput) {
    authors(filters: $filters) {
      id
      name
      description
      conditions {
        name
      }
      isVoice
    }
    authorTotals {
      pageCount
      count
    }
  }
`;

const getQuery = gql`
  query GetAuthor($id: String!) {
    author(id: $id) {
      id
      name
      description
      conditions {
        id
        name
      }
      image {
        id
        url
      }
      isVoice
    }
  }
`;

const createMutation = gql`
  mutation CreateAuthor($input: CreateAuthorInput!) {
    createAuthor(input: $input) {
      id
      name
    }
  }
`;

const updateMutation = gql`
  mutation UpdateAuthor($id: String!, $input: UpdateAuthorInput!) {
    updateAuthor(id: $id, input: $input) {
      id
    }
  }
`;

const deleteMutation = gql`
  mutation DeleteAuthor($id: String!) {
    deleteAuthor(id: $id) {
      id
    }
  }
`;

export default class AuthorApolloService extends ApolloService {
  constructor(entity) {
    super(entity, {
      createMutation: createMutation,
      updateMutation: updateMutation,
      deleteMutation: deleteMutation,
      getQuery: getQuery,
      getAllQuery: getAllQuery,
      getAllQueryResponseTransform: (data) => ({
        data: data.authors,
        total: data.authorTotals,
      }),
    });
  }
}
